import {
  FormControl,
  Grid,
  MenuItem,
  TableCell,
  Typography,
  styled,
} from '@mui/material';
import {
  ROLE_CONFIGURATION_CONSTANTS,
  ACCESS_CONFIGURATION_CONSTANTS,
} from '../../constants';
import { Controller } from 'react-hook-form';
import ErrorExclamationIcon from '../../assets/img/errorExclamation.svg';
import React from 'react';
import { USER_CONFIG_MESSAGES } from '../../constants/en-us';
import InfoIcon from '../../assets/img/infoIcon.svg';
import { StyledSelectbox, StyledTextBox, CustomTooltip } from '../style';
import { andCheck, ternaryFunc } from '../../utils/helpers';

const TableCardHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ _theme, disabled }) => {
  return {
    paddingBottom: '0.75em',
    fontSize: '14px',
    ...(disabled && { opacity: '0.5' }),
  };
});

const InputBoxGenerator = ({
  placeholder,
  gridSize,
  label,
  type,
  id,
  required,
  pattern,
  messageEmpty,
  messageInvalid,
  maxLength = null,
  control,
  setOptions,
  watch,
}) => {
  const watchFields = watch();
  return (
    <Grid
      item
      xs={gridSize}
      sx={{
        ...(label !== ROLE_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2] && {
          marginBottom: '3em',
        }),
      }}
    >
      <FormControl fullWidth variant="outlined">
        <Controller
          name={id}
          render={({ field, fieldState: { error } }) => (
            <>
              <Typography sx={{ paddingBottom: '1.1875em' }} variant="subtitle2">
                {label}
                {ternaryFunc(
                  required,
                  <Typography
                    sx={{
                      display: 'inline',
                      color: 'background.errorColor',
                      paddingLeft: '0.5em',
                    }}
                    data-testid={`asterix-${id}`}
                  >
                    {ACCESS_CONFIGURATION_CONSTANTS.ASTERIX}
                  </Typography>,
                  null
                )}
              </Typography>
              {label === ROLE_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[1] ? (
                <StyledSelectbox
                  placeholderStyle={!watchFields[id]}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    getContentAnchorEl: null,
                  }}
                  {...field}
                  errorStyle={error && error.message}
                  value={ternaryFunc(watchFields[id], watchFields[id], 'none')}
                  data-testid={`selectbox-${id}`}
                >
                  <MenuItem value="none" disabled>
                    {ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_PLACEHOLDERS[4]}
                  </MenuItem>
                  {ternaryFunc(
                    setOptions(4),
                    setOptions(4).map((option) => (
                      <MenuItem key={option.id} value={option.id} data-testid={`selectbox-${id}-${option.id}`}>
                        {option.configDisplayValue}
                      </MenuItem>
                    )),
                    null
                  )}
                </StyledSelectbox>
              ) : (
                <StyledTextBox
                  placeholder={placeholder}
                  {...field}
                  id={id}
                  multiline={
                    label === ROLE_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2]
                  }
                  variant="outlined"
                  type={type}
                  errorStyle={error && error.message}
                  InputLabelProps={{ shrink: false }}
                  inputProps={maxLength && { maxLength }}
                  data-testid={`inputbox-${id}`}
                />
              )}
              {error && (
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.white,
                    fontSize: '0.75em',
                    marginTop: '0.5em',
                  }}
                  varient="caption"
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: '2em',
                      verticalAlign: 'top',
                    }}
                  >
                    <ErrorExclamationIcon />
                  </span>
                  <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }} data-testid={`errorspan-${id}`}>
                    {error.message}
                  </span>
                </Typography>
              )}
            </>
          )}
          control={control}
          defaultValue=""
          rules={{
            required: required ? messageEmpty : false,
            pattern: {
              value: pattern,
              message: messageInvalid,
            },
          }}
        />
      </FormControl>
    </Grid>
  );
};

const TableCellGenerator = ({
  row,
  label,
  updateTableCellValuesForRow,
  index,
  options,
  isSubmitButtonClicked,
  isAnyEmptyFieldPresentInForm,
  disableBusinessOptions
}) => {
  const isNonMandatory = () => {
    return row.disabled
      && (label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1]
            || label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3])
  }
  return (
    <TableCell
      component="th"
      scope="row"
      width="24%"
      sx={{
        ...(label !== ROLE_CONFIGURATION_CONSTANTS.TEXT_FIELD_CONSTANTS[2] && {
          paddingLeft: '1.75em',
        }),
      }}
    >
      <TableCardHeader
        variant="subtitle2"
        disabled={
          (label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1] ||
            label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3]) &&
          row.disabled
        }
      >
        {label}
        <Typography
          sx={{ display: 'inline', color: 'background.errorColor', paddingLeft: '0.5em' }}
        >
          {ternaryFunc(isNonMandatory(), null, ACCESS_CONFIGURATION_CONSTANTS.ASTERIX)}
        </Typography>
        {ternaryFunc(
          label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[2],
          <CustomTooltip
            title={USER_CONFIG_MESSAGES.TOOLTIP_TEXT.map((text, idx) => (
              <Grid key={text} container direction="row">
                {idx > 0 ? (
                  text.map((innerText, innerIndex) =>
                    innerIndex === 1 ? (
                      <Typography
                        key={innerText}
                        variant="subtitle1"
                        style={{
                          paddingBottom: '4px',
                          fontWeight: 700,
                        }}
                      >
                        {innerText}
                      </Typography>
                    ) : (
                      <Typography
                        key={innerText}
                        variant="subtitle1"
                        style={{
                          paddingBottom: '4px',
                          wordWrap: 'break-word',
                        }}
                      >
                        {innerText}{' '}
                      </Typography>
                    )
                  )
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{
                      paddingBottom: '4px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {text}
                  </Typography>
                )}
              </Grid>
            ))}
            placement="bottom"
            arrow
          >
            <span><InfoIcon style={{ marginLeft: '10px' }} /></span>
          </CustomTooltip>
        )}
      </TableCardHeader>
      <StyledSelectbox
        type="tableDropdown"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
        disabled={ternaryFunc(
          label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1] ||
            label === ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[3],
          row.disabled,
          false
        )}
        errorStyle={andCheck(
          andCheck(
            !row.disabled ||
              label !== ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1],
            row[ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] === null
          ),
          isSubmitButtonClicked || isAnyEmptyFieldPresentInForm
        )}
        placeholderStyle={!row[ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]]}
        value={row[ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] || 'none'}
        onChange={(e) =>
          updateTableCellValuesForRow(
            row.rowID,
            ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index],
            e.target.value
          )
        }
      >
        <MenuItem value="none" disabled>
          {ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_PLACEHOLDERS[index]}
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            disabled={disableBusinessOptions(option.id, row.rowID)}
          >
            {option.configDisplayValue}
          </MenuItem>
        ))}
      </StyledSelectbox>
      {andCheck(
        andCheck(
          !row.disabled ||
            label !== ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_CONSTANTS[1],
          row[ROLE_CONFIGURATION_CONSTANTS.TABLE_HEADER_KEYS[index]] === null
        ),
        isSubmitButtonClicked || isAnyEmptyFieldPresentInForm
      ) && (
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
          }}
          varient="caption"
        >
          <span style={{ display: 'inline-block', width: '2em', verticalAlign: 'top' }}>
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {ROLE_CONFIGURATION_CONSTANTS.ERROR_MESSAGE_DROPDOWN}
          </span>
        </Typography>
      )}
    </TableCell>
  );
};

const INITIAL_TABLE_STATE = [
  {
    rowID: 0,
    level: null,
    businessUnit: null,
    permission: null,
    landingPage: null,
    disabled: false,
    roleId: null,
    isCreated: true,
    isModified: false,
    isDeleted: false,
  },
];

export { InputBoxGenerator, TableCellGenerator, INITIAL_TABLE_STATE };
